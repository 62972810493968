import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/service/alert.service';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/service/auth.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: [ './login.component.scss' ]
})
export class LoginComponent implements OnInit {
	public loginForm: FormGroup;
	public returnUrl: string;
	public isLoading: boolean;

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthService,
		private alertService: AlertService
	) {}

	ngOnInit(): void {
		this.loginForm = this.formBuilder.group({
			username: [ 'ritek', Validators.required ],
			password: [ 'ritek', Validators.required ]
		});

		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
		this.isLoading = false;
		this.authenticationService.getInfo().pipe(first()).subscribe((data) => {
			if (!data['success']) this.isLoading = true;
			else this.navigate(this.returnUrl);
		});
	}

	onFormSubmit() {
		if (this.loginForm.invalid) {
			this.alertService.error('Töltsön ki minden mezőt!');
			return;
		}

		this.authenticationService
			.login(this.loginForm.value.username, this.loginForm.value.password)
			.pipe(first())
			.subscribe(
				(data) => {
					if (data['success']) this.navigate(this.returnUrl);
					else this.alertService.error(data['msg']);
				},
				(error) => {
					this.alertService.error(error);
				}
			);
	}

	private navigate(returnUrl: string) {
		if (returnUrl.startsWith('/edit') && returnUrl.includes('?')) {
			const [ url, paramString ] = returnUrl.split('?');
			let [ guid, cguid ] = paramString.split('&');
			guid = guid.split('=')[1];
			cguid = cguid.split('=')[1];
			this.router.navigate([ url ], { queryParams: { guid, cguid } });
		} else this.router.navigate([ returnUrl ]);
	}
}
