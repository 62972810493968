import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './page/home/home.component';
import { LoginComponent } from './page/login/login.component';
import { AuthGuard } from './_helper/auth.guard';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent,
		canActivate: [ AuthGuard ]
	},
	{
		path: 'proba',
		component: HomeComponent,
		canActivate: [ AuthGuard ]
	},
	{ path: 'login', component: LoginComponent },
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [ RouterModule.forRoot(routes) ],
	exports: [ RouterModule ]
})
export class AppRoutingModule {}
