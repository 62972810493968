import { Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[appMenuline]'
})
export class MenulineDirective {
	constructor(private el: ElementRef) {
		setTimeout(() => {
			this.el.nativeElement.style.height = `${this.el.nativeElement.parentElement.clientHeight /
				(this.el.nativeElement.parentElement.childElementCount - 1)}px`;
		}, 0);
	}
}
