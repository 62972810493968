import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/service/http.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: [ './home.component.scss' ]
})
export class HomeComponent implements OnInit {
	constructor(private http: HttpService) {}

	ngOnInit(): void {
		this.http.call({ type: 'search' }).toPromise().then((res) => {
			console.log(res);
		});
	}
}
