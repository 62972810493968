import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './page/home/home.component';
import { LoginComponent } from './page/login/login.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { MatRippleModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { NewPasswordModalComponent } from './component/modal/new-password-modal/new-password-modal.component';
import { MenulineDirective } from './directive/menuline.directive';

@NgModule({
	declarations: [ AppComponent, HomeComponent, LoginComponent, SidebarComponent, NewPasswordModalComponent, MenulineDirective ],
	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MatIconModule,
		ReactiveFormsModule,
		FormsModule,
		MatRippleModule,
		MatDialogModule,
		MatSnackBarModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule
	],
	providers: [],
	bootstrap: [ AppComponent ]
})
export class AppModule {}
