import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
	LoginRequest,
	Type,
	PasswordChangeRequest,
	HttpResponse,
	SearchRequest,
	StatusRequest
} from '../interface/http';

@Injectable({
	providedIn: 'root'
})
export class HttpService {
	constructor(private http: HttpClient) {}

	call(args: Type | LoginRequest | PasswordChangeRequest | SearchRequest | StatusRequest) {
		const data = createFormData({ data: window.btoa(JSON.stringify(args)) });
		return this.http.post<HttpResponse>(`${environment.reqUrl}/call.php`, data, { withCredentials: true });
	}
}

function createFormData(args): FormData {
	let data = new FormData();
	Object.keys(args).forEach((i) => {
		if ((typeof args[i] !== 'boolean' && args[i]) || typeof args[i] === 'boolean') data.append(i, args[i]);
	});
	return data;
}
