import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/service/http.service';
import { ModalService } from 'src/app/service/modal.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { ModalData } from 'src/app/interface/http';

@Component({
	selector: 'app-new-password-modal',
	templateUrl: './new-password-modal.component.html',
	styleUrls: [ './new-password-modal.component.scss' ]
})
export class NewPasswordModalComponent {
	formGroup: FormGroup;

	MustMatch(controlName: string, matchingControlName: string) {
		return (formGroup: FormGroup) => {
			const control = formGroup.controls[controlName];
			const matchingControl = formGroup.controls[matchingControlName];

			if (matchingControl.errors && !matchingControl.errors.mustMatch) {
				return;
			}

			if (control.value !== matchingControl.value) {
				matchingControl.setErrors({ mustMatch: true });
			} else {
				matchingControl.setErrors(null);
			}
		};
	}

	get f() {
		return this.formGroup.controls;
	}

	constructor(
		private _formBuilder: FormBuilder,
		private http: HttpService,
		private modal: ModalService,
		public dialogRef: MatDialogRef<NewPasswordModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ModalData,
		private router: Router
	) {
		this.formGroup = this._formBuilder.group(
			{
				password_old: [ '', [ Validators.required ] ],
				password: [ '', [ Validators.required, Validators.minLength(6) ] ],
				confirmPassword: [ '', Validators.required ]
			},
			{
				validator: this.MustMatch('password', 'confirmPassword')
			}
		);
		this.router.events.subscribe((dt) => {
			if (dt instanceof NavigationEnd) {
				if (dt['url'] === '/login') this.dialogRef.close();
			}
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	handleSubmit() {
		let obj = {};
		Object.keys(this.formGroup.controls).forEach((field) => {
			const control = this.formGroup.get(field);
			control.markAsTouched({ onlySelf: true });
			obj[field] = control.value;
		});

		if (!this.formGroup.valid) return;
		this.http
			.call({ type: 'change_password', password_old: obj['password_old'], password_new: obj['password'] })
			.toPromise()
			.then((res) => {
				if (res['success']) {
					this.router.navigate([ 'login' ]);
					this.modal.openSnack({ msg: 'Sikeres jelszó módosítás!', class: 'success' });
				} else {
					this.modal.openSnack({ msg: res['msg'], class: 'error' });
				}
			});
	}
}
