import { Component } from '@angular/core';
import { AlertService } from './service/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from './service/auth.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [ './app.component.scss' ]
})
export class AppComponent {
	title = 'client';
	public isLoggedIn;

	constructor(
		private alertService: AlertService,
		//private snack: SnackService,
		private authenticationService: AuthService,
		private router: Router,
		//private modal: ModalService,
		private route: ActivatedRoute
	) {}

	ngOnInit() {
		let timer;
		this.authenticationService.currentUser.subscribe((dt) => {
			this.isLoggedIn = dt;
			if (dt) {
				this.alertService.success(`Sikeres bejelentkezés | ${dt['name']}`);
				timer = setInterval(() => {
					this.authenticationService.getInfo().pipe(first()).subscribe((data) => {
						if (!data['success']) {
							clearInterval(timer);
							this.alertService.error('Lejárt a bejelentkezés!');
							this.router.navigate([ 'login' ], { queryParams: { returnUrl: this.route.url } });
						}
					});
				}, 60000);
				if (dt.lejart) {
					//this.modal.openNewPasswordDialog().then((res) => {});
				}
			} else if (timer) clearInterval(timer);
		});
		this.alertService.getAlert().subscribe((dt) => {
			if (dt)
				switch (dt['type']) {
					case 'error':
						//this.snack.openErrorSnack(dt['text']);
						break;
					case 'success':
						//this.snack.openSuccessSnack(dt['text']);
						break;
					default:
						break;
				}
		});
	}
}
