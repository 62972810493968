import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertService } from '../service/alert.service';
import { Router } from '@angular/router';
import { HttpService } from '../service/http.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private currentUserSubject: BehaviorSubject<any>;
	public currentUser: Observable<any>;

	constructor(private http: HttpService, private alert: AlertService, private router: Router) {
		this.currentUserSubject = new BehaviorSubject<any>(null);
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public get currentUserValue(): any {
		return this.currentUserSubject.value;
	}

	getInfo() {
		return this.http.call({ type: 'user_info' }).pipe(
			map((user) => {
				if (user && user['success']) {
					if (!this.currentUserSubject.value) this.currentUserSubject.next(user);
				} else if (this.currentUserSubject.value) this.currentUserSubject.next(null);

				return user;
			})
		);
	}

	login(username: string, password: string) {
		return this.http.call({ type: 'login', username, password }).pipe(
			map((user) => {
				if (user && user['success']) {
					if (!this.currentUserSubject.value) this.currentUserSubject.next(user);
				}

				return user;
			})
		);
	}

	logout() {
		this.http.call({ type: 'logout' }).toPromise().then((user) => {
			if (user['success']) {
				this.currentUserSubject.next(null);
			}
		});
	}
}
