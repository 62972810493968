<div class="menuContainer" [ngClass]="{'closed': !isExpanded}">
    <div class="expandContainer">
        <div class="expandButton">
            <mat-icon *ngIf="!isExpanded" (click)="handleMenuExpand()" [@rotateInOut]>menu</mat-icon>
            <mat-icon *ngIf="isExpanded" (click)="handleMenuExpand()" [@rotateInOut]>close</mat-icon>
        </div>
    </div>

    <div class="navMenu">
        <div appMenuline [style.top]="lineTop" class="leftLine"></div>
        <div matRipple [matRippleColor]="'rgba(0, 0, 0, .15)'" class="listItem" routerLinkActive="active" routerLink=""
            [routerLinkActiveOptions]="{exact:true}">
            <mat-icon>home</mat-icon>
            <div class="listItemText">Főoldal</div>
        </div>
    </div>

    <div class="logoutContainer">
        <div (click)="handlePasswordChange()" matRipple [matRippleColor]="'rgba(0, 0, 0, .15)'" class="listItem">
            <mat-icon>vpn_key</mat-icon>
            <div class="listItemText">Jelszó változtatás</div>
        </div>
        <div (click)="handleLogout()" matRipple [matRippleColor]="'rgba(0, 0, 0, .15)'" class="listItem">
            <mat-icon>directions_run</mat-icon>
            <div class="listItemText">Kijelentkezés</div>
        </div>
    </div>
</div>