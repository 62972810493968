<div class="loginPage">
    <div class="loginContainer">
        <div class="title"> Bejelentkezés </div>
        <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
            <div class="inputContainer">
                <mat-icon>person</mat-icon>
                <input type="text" formControlName="username" placeholder="Felhasználónév">
            </div>
            <div class="inputContainer">
                <mat-icon>vpn_key</mat-icon>
                <input type="password" formControlName="password" name="" id="" placeholder="Jelszó">
            </div>
            <div class="buttonContainer">
                <button type="submit">Tovább</button>
            </div>

        </form>
    </div>
</div>