import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, DialogPosition } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { ModalData } from '../interface/http';

interface Snack {
	msg: string;
	class: 'error' | 'success';
	duration?: number;
	horizontalPosiotion?: 'start' | 'center' | 'end' | 'left' | 'right';
	verticalPosiotion?: 'top' | 'bottom';
}

interface Modal {
	component: ComponentType<unknown> | TemplateRef<unknown>;
	config?: {
		ariaDescribedBy?: string | null;
		ariaLabel?: string | null;
		ariaLabelledBy?: string | null;
		autoFocus?: boolean;
		backdropClass?: string | string[];
		closeOnNavigation?: boolean;
		disableClose?: boolean;
		data?: ModalData | null;
		hasBackdrop?: boolean;
		height?: string;
		maxHeight?: number | string;
		minHeight?: number | string;
		width?: string;
		maxWidth?: number | string;
		minWidth?: number | string;
		panelClass?: string | string[];
		position?: DialogPosition;
	};
}

@Injectable({
	providedIn: 'root'
})
export class ModalService {
	constructor(private _snackBar: MatSnackBar, private dialog: MatDialog) {}

	openSnack(args: Snack) {
		this._snackBar.open(args.msg, 'Bezár', {
			duration: args.duration || 3000,
			horizontalPosition: args.horizontalPosiotion || 'center',
			verticalPosition: args.verticalPosiotion || 'top',
			panelClass: args.class
		});
	}

	openDialog(args: Modal) {
		return new Promise((resolve, reject) => {
			let dialog = this.dialog.open(args.component, args.config || {});

			dialog.afterClosed().subscribe((res) => {
				if (res) resolve(res);
			});
		});
	}
}
