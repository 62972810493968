import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpService } from 'src/app/service/http.service';
import { ModalService } from '../../service/modal.service';
import { AuthService } from 'src/app/service/auth.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { NewPasswordModalComponent } from '../modal/new-password-modal/new-password-modal.component';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: [ './sidebar.component.scss' ],
	animations: [
		trigger('rotateInOut', [
			transition(':enter', [
				style({ opacity: 0, transform: 'rotate(180deg)' }),
				animate('.2s cubic-bezier(.4, 0, .2, 1)', style({ opacity: 1, transform: 'rotate(0deg)' }))
			]),
			transition(':leave', [
				style({ opacity: 1, transform: 'rotate(0deg)' }),
				animate('.2s cubic-bezier(.4, 0, .2, 1)', style({ opacity: 0, transform: 'rotate(180deg)' }))
			])
		])
	]
})
export class SidebarComponent implements OnInit {
	user: string;
	lineTop: string;
	isExpanded: boolean;
	//menu: MenuType[];

	constructor(
		private router: Router,
		private authenticationService: AuthService,
		private el: ElementRef,
		private modal: ModalService //private http: HttpService,
	) {}

	ngOnInit(): void {
		this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				setTimeout(() => {
					let active = this.el.nativeElement.querySelector('.active');
					if (active) this.lineTop = `${active.offsetTop}px`;
				}, 0);
			}
		});
	}

	handleMenuExpand() {
		this.isExpanded = !this.isExpanded;
	}

	handlePasswordChange() {
		this.modal
			.openDialog({
				component: NewPasswordModalComponent,
				config: {
					data: {
						title: 'Jelszó változtatás'
					}
				}
			})
			.then((res) => console.log(res));
	}

	handleLogout() {
		this.authenticationService.logout();
		this.router.navigate([ '/login' ]);
	}
}
