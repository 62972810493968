<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <form [formGroup]="formGroup" autocomplete="off">
    <mat-form-field>
      <mat-label>Előző jelszó</mat-label>
      <input matInput type="password_old" type="password" formControlName="password_old" placeholder="">
      <mat-error *ngIf="f.password_old.errors && f.password_old.errors.required">
        A jelszó szükséges!
      </mat-error>
      <mat-error *ngIf="f.password_old.errors && f.password_old.errors.minlength">
        Kevesebb mint 6 karakter!
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Új jelszó</mat-label>
      <input matInput type="password" formControlName="password" placeholder="">
      <mat-error *ngIf="f.password.errors && f.password.errors.required">
        A jelszó szükséges!
      </mat-error>
      <mat-error *ngIf="f.password.errors && f.password.errors.minlength">
        Kevesebb mint 6 karakter!
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Jelszó ismét</mat-label>
      <input matInput type="password" formControlName="confirmPassword" placeholder="">
      <mat-error *ngIf="f.confirmPassword.errors && f.confirmPassword.errors.required">
        A jelszó szükséges!
      </mat-error>
      <mat-error *ngIf="f.confirmPassword.errors && f.confirmPassword.errors.mustMatch">
        A jelszó mezők nem egyeznek meg!
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="handleSubmit()">Mentés</button>
</mat-dialog-actions>